.register-table-container {
    .register-table-no-topic {
        .MuiPaper-root {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding: 40px 0;
            box-shadow: 0px 4px 8px rgba(214, 209, 203, 0.5);
            .common_status_container {
                p {
                    width: 263px;
                }
            }
        }
    }
    .register-table-snackbar {
        margin-bottom: 24px;
        .MuiPaper-root {
            display: flex;
            flex-direction: row;
            padding: 0;
            .MuiAlert-icon {
                margin: 0 15px 0 15px;
            }
            .MuiAlert-action {
                margin-right: 0;
            }
        }
    }
    .scroll_button_parent {
        display: flex;
        justify-content: space-between;
        .arrow {
            font-size: 40px;
            cursor: pointer;
        }
    }
    .table-container {
        width: 100%;
        padding: 0 0 0 5px;
        margin: 0 0 0 -5px;
        background-color: #fff;

        .table-content {
            width: 100%;
            border-collapse: separate;
            border: 1px solid #e0e0e0;
            min-width: 100%;
            border: none;

            .table-head {
                cursor: pointer;
                position: relative;
                .MuiTableRow-head {
                    height: 250px;
                    border-top: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                }
                th:first-child::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    height: 100%;
                    bottom: 0;
                    width: 4px;
                    left: -5px;
                    background-color: #fcfcfd;
                }
                th:first-child {
                    border-right: none;
                }
                .nested-table-cell {
                    border-right: 0;
                    border-left: 0;
                    height: 250px;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 50px;
                    max-width: 50px;
                    min-width: 50px;
                    text-align: center;
                    position: relative;
                    .rotated-text {
                        transform: rotate(-90deg);
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 50px;
                        width: 100px;
                        text-align: initial;
                        margin: 0 auto;
                        .rotated-releaseDate {
                            line-height: 1rem;
                            color: #999;

                            .for-group {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .group-chip {
                                    font-size: 12px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                    .bottom-text {
                        bottom: 80px;
                    }
                    .text-bottom {
                        position: absolute;
                        bottom: 10px;
                        justify-content: center;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 10px;
                        width: 100px;
                        text-align: left;
                        text-align: initial;
                        margin: 0 auto;
                        text-align: center;
                        .common_drop_down {
                            display: block;
                            color: map-get($theme-colors, dark-warm-grey);
                            font-family: Chaloops-Medium;
                            font-size: 20px;
                            font-style: normal;
                        }
                        .holiday_text {
                            height: 17px;
                            color: map-get($theme-colors, dark-warm-grey);
                            text-align: center;
                            font-family: Verdana;
                            font-size: 12px;
                            font-weight: 700;
                            width: auto;
                            border-radius: 8px;
                            background: map-get($theme-colors, base-off-white);
                            width: fit-content;
                            padding: 0px 8px 2px;
                            box-shadow: 0px 2px 4px 0px rgba(214, 209, 203, 0.25);
                            margin: auto;
                            margin-bottom: 4px;
                        }
                        .calendar {
                            display: flex;
                            justify-content: center;
                            gap: 3px;
                            .show_Activity_count {
                                display: flex;
                            }
                        }
                    }
                }
            }
            .activity-grid-table-header {
                th:first-child {
                    border-right: 1px solid #e0e0e0;
                }
            }
            .table-body {
                position: relative;
                .table-cell {
                    a {
                        text-decoration: none;
                        color: map-get($theme-colors, base-black);
                    }
                }
                .pupil-name-status-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .info_blue_color {
                        color: map-get($theme-colors, auxiliary-blue-base);
                    }
                    .info_yellow_color {
                        color: map-get($theme-colors, auxiliary-yellow-base);
                    }
                    .warning_icon_color {
                        color: map-get($theme-colors, auxiliary-red-base);
                    }
                }
                .show_Activity_count {
                    img {
                        position: relative;
                    }
                    p {
                        position: absolute;
                        font-family: Chaloops-Medium;
                        color: #fcfcfd;
                        top: -10px;
                        right: 50%;
                        transform: translate(50%, 50%);
                    }
                }
                .table-row th {
                    background-color: #fff;

                    position: relative;
                }
                .table-row th::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    height: 100%;
                    bottom: 0;
                    width: 4px;
                    left: -6px;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                .border-warning::before {
                    border: 1px solid map-get($theme-colors, auxiliary-yellow-base);
                    background-color: map-get($theme-colors, auxiliary-yellow-base);
                }
                .border-active::before {
                    border: 1px solid map-get($theme-colors, auxiliary-green-base);
                    background-color: map-get($theme-colors, auxiliary-green-base);
                }
                .border-inactive::before {
                    border: 1px solid #99c95d;
                    background-color: #99c95d;
                }
            }
        }

        .group-BG {
            background-color: #fafafa;
        }
    }

    .table-cell {
        // border: 1px solid map-get($theme-colors, base-background-light-grey);
        border-bottom: 1px solid map-get($theme-colors, base-background-light-grey);
        border-right: 1px solid map-get($theme-colors, base-background-light-grey);
        .common_drop_down {
            display: flex;
            justify-content: center;
        }
    }
    .grey-table-cell {
        background-color: map-get($theme-colors, base-off-white);
    }
    .border_none {
        border-bottom: 1px solid map-get($theme-colors, base-background-light-grey);
    }
    .minus_icon_color {
        color: map-get($theme-colors, base-warm-grey) !important;
    }
    .schedule_icon_color {
        color: map-get($theme-colors, principal-main-base) !important;
    }
    .check_circle_icon_color {
        color: map-get($theme-colors, auxiliary-green-base) !important;
    }
    .info_snackbar {
        background-color: map-get($theme-colors, auxiliary-blue-light) !important;
    }
    .certificate_snackbar {
        background-color: map-get($theme-colors, principal-maths-light) !important;
    }
    .info_snackbar_icon {
        color: map-get($theme-colors, auxiliary-blue-base) !important;
    }
    .certificate_snackbar_icon {
        color: map-get($theme-colors, principal-maths-dark) !important;
    }
    .help_snackbar_icon {
        color: map-get($theme-colors, auxiliary-yellow-base) !important;
    }
    .snackbar_icon {
        font-size: 33px;
        height: 35px;
    }

    .activity-grid-subject-filter {
        .button_group_btn_group {
            button {
                padding: 8px;
            }
            .empty-circle {
                width: 20px;
                height: 20px;
                border: 1px solid map-get($theme-colors, base-warm-grey);
                border-radius: 100%;
                box-sizing: border-box;
                margin: 2px;
            }
        }
    }
}
.extra-width {
    min-width: 130px;
}
.fiexd-col {
    &:first-child {
        position: sticky !important;
        left: 0;
        z-index: 5;
        cursor: pointer;
        min-width: 150px;
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #fff;
    }
}

.main-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    border-color: red;
}

.pupil_table_body_container {
    padding: 0px 5px;
    margin: 0 0 0 -5px;
    width: calc(100% - 6px);

    .pupil_table_body {
        position: relative;

        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 4px 8px 0px rgba(214, 209, 203, 0.5);

        thead {
            background-color: map-get($theme-colors, base-background-grey);
            th {
                font-family: Verdana;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
            }
        }

        .table-cell {
            a {
                text-decoration: none;
                color: map-get($theme-colors, base-black);
            }
        }

        .pupil-name-status-container {
            display: flex;
            align-items: center;
            gap: 5px;
            .info_blue_color {
                color: map-get($theme-colors, auxiliary-blue-base);
            }
            .info_yellow_color {
                color: map-get($theme-colors, auxiliary-yellow-base);
            }
            .warning_icon_color {
                color: map-get($theme-colors, auxiliary-red-base);
            }
        }

        .table-row td {
            background-color: #fff;
            position: relative;
        }

        .table-row td::before {
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            bottom: 0;
            width: 4px;
            left: -6px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .border-warning::before {
            border: 1px solid map-get($theme-colors, auxiliary-yellow-base);
            background-color: map-get($theme-colors, auxiliary-yellow-base);
        }

        .border-active::before {
            border: 1px solid map-get($theme-colors, auxiliary-green-base);
            background-color: map-get($theme-colors, auxiliary-green-base);
        }

        .border-inactive::before {
            border: 1px solid #99c95d;
            background-color: #99c95d;
        }
    }
}
