.school-pupil-tag {
    .pupil-tag-heading {
        font-size: 24px;
    }
    .pupil-tag-info {
        background-color: #e5f3fa;
        display: flex;
        padding: 16px;
        gap: 16px;
        p {
            margin-top: 5px;
            font-family: Verdana;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }
        margin: 20px 0px;
    }
    .snackbar {
        .info_snackbar {
            margin-top: 8px;
            .info_snackbar_icon {
                color: map-get($theme-colors, auxiliary-blue-base) !important;
            }
        }
    }
}

.warm-grey-color {
    color: map-get($theme-colors, dark-warm-grey);
}
