.change-reading-week-start {
    p {
        padding: 1em;
    }
    strong {
        text-decoration: underline;
        text-decoration-color: map-get($theme-colors, auxiliary-red-base);
    }
}

.update-reading-week-confirmation-modal em {
    color: map-get($theme-colors, auxiliary-red-base);
    font-style: normal;
}
